import { Config } from "./ConfigType"

const config: Config = {
  chains: [
    {
      chainId: 324,
      name: "zkSync",
      USDC_NAME: "zk_USDC",
      tokenAddress: "0xcd98e2C68248de044c3E44144C34D9EBb09337a9",
    },
    {
      chainId: 1101,
      name: "Polygon zkEVM",
      USDC_NAME: "polygon_zkevm_USDC",
      tokenAddress: "0x96DaD05740807e76892076684F433D5E0b3569fB",
    },
  ],
  networkId: 324, // default network
  silentTokenAddress: "0xcd98e2C68248de044c3E44144C34D9EBb09337a9",
  massTransfer: [
    "0x3d0ade8b4146e0438f7f872d4b8c4155bfac5b56",
    "0x7e74d1beca737a4a6fae4190c258e087ad3f3130",
    "0xd345be10ea73eb79d0dc090513407779c17bc312",
    "0x4e5b362a6dcdeba1d599904c96195263f6ffc177",
    "0xc9ccf373491a3bc1d3b18f84388234533be80fd6",
    "0xf792145d4c76584dee915e77b01d370d95f5f52a",
    "0x8a7ead456a44330031042452ea6c61ee3087ce9f",
    "0xb11b665af84b61d31c63e80eb88cf97d85b35fff",
    "0x9817c8ba011c4976736fbbb914a1bfb58bdd11ca",
    "0xfb54207c9e68c83f72d10564ee3012c19aac547c",
    "0x58e991a2b14182737a51807fc1a64d6a04bcca59",
    "0xc8e087015c051935f0f75fc47ec672867e1660f8",
    "0xb8ffd3bfb55f3caf13f503f125ec5954063cc1b2",
    "0x03c18f5dc89bb75a065135e100f88424f1a3a84f",
    "0x913e50705bbf4aecc6359945109018d6a98e0e9e",
    "0x447ce12a2a3464fd0d448f92cfc0ac858620fff7",
    "0x5cc9ac6d63759e97b48dc7e3c30ee1e3aec17e06",
    "0xfbb870f1d39029a820396d22133dc2641ec0650b",
    "0xdd8d5ac01413e5873198c0e2b40ada9b26a51e30",
    "0x5871ca050ab52f43e930d5a99d65f3885012050b",
    "0x76f75fa6b9d6f62916e241ba070efee0ce3280e3",
    "0xb73e174fbabf96252b1c03c4ddb797246955db06",
    "0x2628faa591470ae22c38cd90677ba973c2f3e51b",
    "0x153feeefeb0a5b40260684feaf256726dcccde63",
    "0x2ba79f3f740ea48c3cc651ab81253c97ec591f61",
    "0xd98c786620ac61353b0146cefd719d1acbe36630",
    "0x47e6e4adec76a0963f6f881c6cb852f1323aaf0f",
    "0x54557bf1a302c86c3041e7d73de4953af453e14b",
    "0x5ee34667a0e736eaee42140307a8ad8722635913",
    "0xd3348f3fe8ec1c7f231684976ae0604d04616510",
    "0x6b88cafdab2fdbbc8b97c095a5045994a5bf285d",
    "0x5014ccd0d260448c0d89099da8d64d229b7c321f",
    "0xbc401b021d9a9cee016b843cf3b5cf59dc41602a",
    "0xee14bbebf88b2732d3cb07d9bc6e1193c864862e",
    "0x23aa374886d939a78facabf4f23dca90855b7936",
    "0xfebe1fa8f907d17b5a6253d36ce16b873be5feb6",
    "0x8e2a15c29c704d26e062d5f4b6f9d1a11a2966fa",
    "0x84835718026d550e46b91063ec88f385825132fc",
    "0x8c4fba517f92e8377d1eb59287e63fc48ed62ad2",
    "0x364d5a724ffcdabbd3f1bf6faa758d6c500434d3",
    "0xa10d8949459bc99b61d607824e308c4127a296a4",
    "0xcbf3bd70e2a0b458ed4e78974143e745d7d64861",
    "0xd4f0177417f21c311a235d6c1d89e657fbda29bc",
    "0x3b26ed15898ef8bea632fc4b961d65b6368efd89",
    "0x167a90e32b1b55a3efeb382de1f86d35b7f9885f",
    "0x758e2f79d6d289f5f44ea986d9e84b400e32b449",
    "0xa18447a20b26e7c3a8e91b68e58cb3a8d9415ea3",
    "0xbdc9a122697e69c9a64206d772acd55a27394b83",
    "0x1d46a5c5e6ba5e3e0b77e4c9a1afc1c8458d2314",
    "0xd3b42d5f1af4221e783142597e44c220e5adc1e4",
    "0x70f84ce7c1625af279be85005e933228e190066e",
    "0x661a0cbeb2862633ec0fe3810eec07bfa45feca8",
    "0xb68163343c56fa534a57309f938c03eb3a85e8ed",
    "0xc5cfbe788ff61cb88a25a904e079ef03bf1da0c5",
    "0x865f12132f54c571487a896ab0e122fed3e4b456",
    "0x03a77fd6439fd96e31afc20bda1b6e7fb22b1ded",
    "0x9fe1dd7a63b66668a5a9ddcbd94d9f02e338af79",
    "0x45f0b718fa2194c5af02268df7d00a01b5970821",
    "0x43ac62fe9de20f00ec1c62b914eb923df45ece36",
    "0x945671826178d3bdd84db150a688de46b03f1fde",
    "0x43143b2cc05b9005fe7868a0b648e722cf6f6e30",
    "0x250d16d4a80e9fb0861d401675bbcf694dc9b94f",
    "0xa0e28050a7e8a5038f12597a5abf672f3e0423c4",
    "0x6e7fa1f2944d7943a7eef8a2979666447f3fe6ef",
    "0x3207dfde95693d435f79b5872b415d0dde1cdf17",
    "0x6d5e5db6cf144504f20f4f36289a49323f1a91fa",
    "0xd3e5116abd1747689a5a8220caeda44bc22c83a6",
    "0xdfe18278da40abc2910cb4f7d4318b0cb979bbea",
    "0xa4a93fa4ef3b49b6ce98cab137a314cfb4802f4d",
    "0xe9ca960b1c30790173e5410b47d49aec7815ff1e",
    "0xa65aaf6b43daac6bc3108f6a2412a0c920ff1c4e",
    "0xa8e94851b706fe60156f858f955199695b420777",
    "0xef9db277aac9ffb765749d9d6e253f199604d6f0",
    "0xd7e853ddfa2205f64d561ae2c61820ca07e2862b",
    "0xc36ab33d653e20bc81e00186fb18b4c60847c8eb",
    "0xa8b69ba2b5420fe26aa4763b1f58e4862dc47ebc",
    "0xc7501475753bb5de133419e741879e7b3d50cae0",
    "0x9996f035405fbc13956a9486d772e5d030d015b0",
    "0x446b692810602ac819bed3ed53da7210fe9a8346",
    "0x91bf9e00342f1a96d759673f012391e54663b218",
    "0x23fc5a0868e7d7887dc4c2e77f7aef9cca5c81f0",
    "0x88c9d36a3e80c775bd9ba60eaf4170e793e9bc54",
    "0x29afc1eb9689243e888983e2cbf9a83fdff64c89",
    "0xbe6e4fdbd695476aa75cab3cde94f1dbf24ee25d",
    "0x69e7c237e5c2f4124ee981b9d71a339c2d3e1374",
    "0x92a34df9d4980d841cb3b8c1d687c3584af51a85",
    "0x54be3000e91b77851d9bc54fda348d0b1fb36156",
    "0x8eb59b95f3e47718ce87dfe267f06c57ec53b5d6",
    "0xffedf9293ccb5b9e4cb0aac79f5245a49b079363",
    "0xd53525f3049c1be9df28683f20ac5caba6dc42aa",
    "0xbb3acbb56e80b280ddc298426c1693c404d672d3",
    "0x97a9b0f56064cef33ee585dbaaeb30869e88ac2d",
    "0xf4352882a9404a5e2de27538f78a05a7c9e2b251",
    "0xf3c5b8c478e9008f4c719bfa29b8562d39843f62",
    "0x151b01d527c54b2ed4a07210c13ea1a8cf3f5f6b",
    "0xfee978d008b5e0274db736d39af256187c54645e",
    "0x6dfe3fa3ea507c3fde299edfeb5577f28bd4a09d",
    "0xefe05b9c0833b9ed4665886ce299c4549fe93399",
    "0x5caad76d7892e67db1ddb79e31514735de15cb70",
    "0xf7568f82917738a96ac3bb9949c4ebe258ca8579",
    "0x9c61b33228fecad33cf0f3e77162b57f33efaa95",
    "0x02da030a4ce7a9774e41da94ad20c13bbbc54292",
    "0x6bfbdf4d03c410d3316153b7179b954accaf05c7",
    "0xe416e5e295794f9d8186df45230f5c2a86a23ac4",
    "0x5c734f19bcbd9e6333d7a0de895a3fd851bd6393",
    "0x5ae9222da40dbde1d378efd0748df04c653cc337",
    "0x71c7c27ca3b2f913967f644d0de565dee35d902e",
    "0xb68f968f1b90d95a317ef1a5578ec64b38699019",
    "0xb4a3a7ae1aedad0f80cc677fb5598582f27b7872",
    "0xf6b2407c48a4b873e9080ef02cf89231fa5fe607",
    "0xbe2945625ff88a3008bd2e5ce60ea6d634341533",
    "0x5a36dc077bc1788b32be52d1205bb9dc19b8e9a1",
    "0x2d4e9329c71e0847a412db2e1419c6734fff2689",
    "0x7ad67025984181aa1eb2a1ee52322572c7b53894",
    "0x34a10490ab97ddafab36dd32933ea75a2f3cff82",
    "0xf6c50fa8f3ef712ba7cddbe3bc00339b5400d1c6",
    "0x58be7309730741ad1618f0ff031eeae97051d901",
    "0xaa30e08e07462b34e5252fd5dca9a60f1c815f5c",
    "0x70c44ed11b93b882de5a38fad5773336e9b06d2a",
    "0x949efc79c6b37d6e81b2fb228528ab1662d8ccc2",
    "0x425d14ad0777047a7bb363679e54b4e94eaf179f",
    "0x30c77e45d9a7392ec52bef2b31d5c712612c90bb",
    "0x15a639d360a69b148bd19a123757ad61312d3eaf",
    "0x39acdbaff3297f0a7eb0c8d0b4899fcb5ae3d6c6",
    "0x93394b58ce353d26ce96516bba8c812286dbb8e6",
    "0x35ca46f410c2d89c33003d0f5a159b6e68a1f82f",
    "0x2be299cf931c0fecf04010c23dbc6cf399e8a59e",
    "0x0eca8c1f2f8a6738ba5b96640d6dc39b4f5291b0",
    "0xde4bb153164d6bae6623d31a122e5c49439bfb16",
    "0x00dd9a5c10936983fac8576a19f93ebb13ce33da",
    "0x5416424591398a88dc187a64d1e5b91d2c09ff8f",
    "0x55dc2d8ed44cc91fe74c80dd47ccc92455d78aaa",
    "0x9d7308d184d6fe267d27323d0e3fe69bd5ebd2d6",
    "0xb0e3db36042991d6cf0f05e8fdb0800f3bd36b08",
    "0x0eb6865a36b97849aed542bef8f08a47e0bec433",
    "0xdd229dd536582ccd98c697a9417f37b5004930c7",
    "0x114c5a266ff7dbc8369e6541797c82f677c3b691",
    "0xa0223d025c312735a3b23a10c901e7a7d00dd6e1",
    "0xfc93ca0bca4854b2e862c015ba281499e704ca77",
    "0xcb4eb1fef1fca908e8c13ea12a58d293252f3765",
    "0x18e3ca1400690d905545dba86b74a86914a9430a",
    "0xb7b1e023abd20a18a47056ab97aba6c340fb3b92",
    "0xf7b3a57e6ad4bbf7f26454cef3fcabc91366cee8",
    "0x4e24da893e0397f6584f282128800b0cbaa27634",
    "0x6004c31f5d3a4df48e7cd25ecc1048b38f424620",
    "0x1b0a26e3d20accdc0619d824ed69d8d797dab6f0",
    "0xa03d5584029474a881c152fe0fb99ea6589a7351",
    "0x2fa8c63288400f86b9e28dbc455f225ef5d3b850",
    "0xb0d310ccdfb6eedc51822f64e8fbbe8d42135bc0",
    "0x6dbb4caf664d0ebfcc1a8648549208e0a363b744",
    "0xc47eb647b6e3cc92cb114f3a8300baa97eabafd7",
    "0xaa98f21a55357e8bb54f60d9b04b658a6e6f4868",
    "0x590f5ab0c8ecca792f730144e74f47f8a2939f84",
    "0xfada8eb9831ec5a0532a2b236820d3d512a92dec",
    "0x3231fca359bd7a1665852fbfc82854b089379335",
    "0xea18233da7aea7b625c8450d240147654b9b6ee5",
    "0x4fd2db6affd4e055c85829d462c62ddbeb578a3b",
    "0xf9e9b5cf83b68acb28940771fa9a1dd1a1c7f9bf",
    "0xfb979c7d554698cc4b5c8aa5fb85c2dd3a74bd23",
    "0x729504817c14af2280468ebba35fb6943736b499",
    "0xcd04916bcd2a103e83075acc3d40e2ae694ecd1c",
    "0x98f89a1b259f1479d9b149dfa95c2f01a38f6fd9",
    "0xe87152c99d413421e304a3a2b0036102be6a234c",
    "0x4562fa671852c808b35cc46259afc78c13a5a168",
    "0x6a931f3b0c23a362422ac22d75b2824292463c6f",
    "0x2b38b75122feddf87af8478547d52ef60ee316c2",
    "0xd2fe6531873ea357b7bbaf9e0a635376d2759b01",
    "0xc12470195365fc03a50fc93e2d0f6e8b334ec80f",
    "0x1d08ea96ab31120f4d0141fc8558415ccef863f9",
    "0x09966be4ee17f825f65816d6994a0544179e8145",
    "0x3bc8d11e0ea2b1c21cd9e11b1d65047602167fd5",
    "0xac1e4f39fadc159cfe5aa93965d8002bd36ba0b1",
    "0x942aef320eb88f71461721cc65dff5dbc8c7a063",
    "0x0c198af2a0c57e9e1744f942c04e82316173e794",
    "0x1694aa610ec8e7e0fb06ee8f044de95afb883962",
    "0xb3dc8d43949646daf9f2bdba17116323bb42dd17",
    "0x1b11b2e8dcdc63a35a8f78490413b80b3e936eae",
    "0x2a9720ebd929ebd4ff4893dd5d2f092caa2b73ce",
    "0xf801689b0a57aad846c12c0bfab90882bcd3fa6d",
    "0x3df4c72be4260b1cc59684294f81d6660b086882",
    "0xc4dffd7e1c1779dc71a4a840bd38a4316006a698",
    "0x0785678ffc137079c8776522c918728e8d0a77e7",
    "0xd8b76c0e45dcaefcc82e0ea22ed0b93bbe3ce283",
    "0x4edfcfd0ab8852365f385e5b2adc1c2cd8151913",
    "0x114a4f5b51964ef1662f9df29f7c738090ebc829",
    "0x87834436986ce3529a61eb36c6d0d82a12db4eb7",
    "0xacf22782eb79a42ed88e09e3087eedd1fa55c5a3",
    "0x3f1ccb770e1d5e0e702068b99d4e46e62690075b",
    "0x365f33306671b8aaebb9fe36443857eaa1d6f49e",
    "0xdc8f0efc92e0dd6b40c33171ab92ed11e9a736b4",
    "0x19e627b7234bd80fdbf1b79774b5f78255e3e867",
    "0x772a52de7ecca6bbfa4e6d5f3aa9730d1390c811",
    "0x0ebf5656c7a0ef570bdedb5cb794d496cc67cd6f",
    "0x73a3f33e6df653149c6571f9ec12b32ab355e2d1",
    "0x601cb7f712c453d05d54e2d2ee40c3ec127b4db2",
    "0xac379bd67187373bf1f281cea4e185e0b7c0d505",
    "0xe792e18ba95429aeed28681b5b57f62f64f35569",
    "0x36888b0c821a19bd8b8b89ad2d019f12506c5eed",
    "0x1c31be7f541ae2067f28811b215c690b271f5e69",
    "0x4c96c36352aa65375b0d86db1645576725de3851",
    "0xf4e941e9930dc55f78aec5113d78631b88130695",
    "0xdbd1183182144ce8c2e3a0e329ac7f6f26d583e7",
    "0xd541d07874cff72986afbb3d58a3ed8204f1e444",
    "0xe2f915e3adcff571de9d6d34de043c2b7aa2b6f5",
    "0x3bc688211480c716e35ed31f62b38a67ccf70f64",
    "0x38a795284cb56702a08ec3f95c88d4e87889051e",
    "0xf1144ccf5c7af2c925471a77985393347f986cac",
    "0x25a852a0af264f23f271e72d147fa3317c22bf51",
    "0x3e01834577e7e72bc8a23f08a55d715b31592215",
    "0x4700ff39037da3b53818ab5b04087995bf1519c1",
    "0xa9993cc06684d33f32e9d4307882e40c171ec370",
    "0x4fd1a0d1b59d046c6edca2731eef70082373bee5",
    "0xdbb3e17d1a1360a2c3ecb13a1596854e2af48d7e",
    "0x9ca514ed24ebc217a0f9fafb8841adf5b09407c0",
    "0xe76b3eb065d2cc7597417a23cbd70e2faed9d0df",
    "0x147fbf6498a0f9d4696fe7b1ebc839db12a21010",
    "0x362eca59f41c1f631f93924862d466b1a7bdcf3c",
    "0xcc76c8481fdd80153ce29c4a53e164b78e2c04f9",
    "0x566e6678416423548bbe447d1b246e03806bb87b",
    "0x820700901b283011f2921675a36211455f56c9ac",
    "0xf94914495d3f5d49a9e9d63d857cb916909b64ec",
    "0x7b92b64c1fc310106d6bc9c9ea2b7bc3cb147b15",
    "0xdf5dd066da978ef30e0d2ad2ab44fefb89f7433f",
    "0x935c8365bff82cad8c353dff9258dceb98b95336",
    "0xb746a01fb90f8bb952d9ffd448416ac423f4ed94",
    "0x8d281194c7a2e9bb2c5dd44997a8d4e535e58148",
    "0xf34173d3a3659d82a2767752525cd99b12c14977",
    "0xc8d9dfbf80805e7b0fd72c37207a6df7629626bc",
    "0xa32f9982d37ec41ff8e5381ecb6bac64600225ec",
    "0x2b6d3d5727d42f4f1e10d45ca8855ac9951d1076",
    "0xc93ecf5732f0ffadb1d5acf2441db65dd883a4b5",
    "0xbbc19155aba31ecce119e6bff300703cd8708e14",
    "0xd8f9541582db658172c397574475144a919c7454",
    "0xad68e8a61a21b9559c12e41edde7f1aa3eb417ae",
    "0x0ab64ea1736696758167c4c39f74b7418d43cb8f",
    "0x26778a36c38a418a3c59fd5cdcd220ff216270ac",
    "0xa144f3b2bc698013252aa3e4cb137749c4ea697d",
    "0x23947bc5c2de9304a1a4c37922cd795c68f5a3e2",
    "0x7a4fc2371bc40dc8600528374040e3757d667726",
    "0x3b62bd556f0f532d4779a8a8e43499f5a18574ae",
    "0x3a6b9344542ff46cba28c3bad1cfd623755fcaa5",
    "0x41c6be9b29cb6d4d429383069fe4575f9e793771",
    "0xb9055c8229a7637dd58d104b473c2981fc674b44",
    "0x06b610ec581d2bd7d7beec95b7b75f40ac89494d",
    "0x95dfa5e893f723a8c371d3ae9d22b36fce94a3f7",
    "0xcff1f451810bf9dc724f1a44ce8441aefe6a7537",
    "0xfa6f4368352c9f2fbabab8d00e52819a649190a1",
    "0xf7018353f23a560e3d58977effbe685827327c01",
    "0x6faeb75f96bde33de3e8c531175c5c44b81d3d5d",
    "0xbb3a20eb1d310a464d7c4e7079b482e71080e618",
    "0xfa68fd6a57f045392abc7c975ce12ee117c270fe",
    "0xb0e023272a8a798b58f32481349c3d1a8afc5413",
    "0x3c57d8837199d0769a2e20cbb6e63b85e680686a",
    "0x9ea9ab624f2ae5bce7242fcfec3338183bb6bbfd",
    "0x1648a7c384e11eb1cadc53be7f5b3d00c5d63c50",
    "0x5a96e7e4f6560800b506c646358c0f2642e9501d",
    "0x546e853c2b207080a4b51e67a8c982442f850154",
    "0x8445d6709025bd456ebc55a0f431abfe26693297",
    "0xc160868a5cbc8ff6b6da98984758f7371175c176",
    "0x2298c9e99f1c7f7ae7122c6aaefec6d518ab9439",
    "0xacd820f74b443e8f4392474bd47f9b0efa505f94",
    "0xcc0b98fe804f8bcdec25300e84aaa7d2e9c712b6",
  ],
}

export default config
