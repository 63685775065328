import React from "react"
import { ERC20 } from "../contracts"
import ERC20ABI from "../abi/ERC20.json"
import { useAccount, useContract, useProvider, useSigner, useNetwork } from "wagmi"
import { BigNumber, ethers } from "ethers"
import config from "../config"

/**
 * Object containing used ERC20 tokens.
 *
 * Adding a new token will update type definitions as well.
 */
const TokenData = {
  USD: {
    contract: "0xAd84341756Bf337f5a0164515b1f6F993D194E1f",
    decimals: 18,
  },
  zk_USDC: {
    contract: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4", // main network
    // contract: "0x0faF6df7054946141266420b43783387A78d82A9", // test network
    decimals: 6,
  },
  polygon_zkevm_USDC: {
    contract: "0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035", // main network
    // contract: "0x0faF6df7054946141266420b43783387A78d82A9", // test network
    decimals: 6,
  },
}

export type AvailableERC20Tokens = keyof typeof TokenData

/**
 * Hook for interacting with an ERC20 token contract
 * @param token ERC20 Token
 * @param contractAddress Contract address for an ERC20 token
 */
const useERC20 = (token: AvailableERC20Tokens) => {
  const { chain } = useNetwork()
  const address = TokenData[token].contract
  if (!address) {
    // throw Error("Address or token name required")
  }

  const [decimals, setDecimals] = React.useState<number>(18)
  const [balance, setBalance] = React.useState<BigNumber>()

  const provider = useProvider({ chainId: chain ? chain.id : config.networkId })
  const { data: signerData } = useSigner()
  const accountData = useAccount()

  const contract: ERC20 = useContract({
    addressOrName: address,
    signerOrProvider: accountData.isConnected ? signerData : provider,
    contractInterface: ERC20ABI.abi,
  })

  React.useEffect(() => {
    if (!contract.provider && !contract.signer) return
    contract.decimals().then((res) => setDecimals(res))
  }, [contract])

  /**
   * Refresh token balance
   */
  const refreshBalance = React.useCallback(async () => {
    if (!contract.signer) return
    const signerAddress = await contract.signer.getAddress()
    const latestBalance = await contract.balanceOf(signerAddress)
    setBalance(latestBalance)
  }, [contract])

  /**
   * Fetch balance of specific address
   */
  const getBalanceOf = React.useCallback(
    async (address: string) => {
      return contract.balanceOf(address)
    },
    [contract]
  )

  /**
   * Fetch the allowance amount for a given spender
   */
  const getAllowance = React.useCallback(
    async (address: string) => {
      if (!contract.signer || !address) return
      const signerAddress = await contract.signer.getAddress()
      return await contract.allowance(signerAddress, address)
    },
    [contract]
  )

  /**
   * Approve spending amount of tokens for a given address
   */
  const approve = React.useCallback(
    (address: string, amount: BigNumber) => {
      if (!address || !amount) return
      return contract.approve(address, amount)
    },
    [contract]
  )

  /**
   * Format amount
   */
  const format = React.useCallback(
    (amount: BigNumber) => {
      return ethers.utils.formatUnits(amount, decimals)
    },
    [decimals]
  )

  /**
   * Refresh balance on initialization, or on account change
   */
  React.useEffect(() => {
    if (!accountData?.address) return
    refreshBalance()
  }, [accountData?.address, refreshBalance])

  return React.useMemo(
    () => ({ balance, refreshBalance, getBalanceOf, getAllowance, approve, decimals, format }),
    [balance, refreshBalance, getAllowance, getBalanceOf, approve, decimals, format]
  )
}

export default useERC20
