import React, { PropsWithChildren } from "react"
import { WagmiConfig, defaultChains, createClient, configureChains, chain } from "wagmi"
import { InjectedConnector } from "wagmi/connectors/injected"
import { WalletConnectConnector } from "wagmi/connectors/walletConnect"
import { publicProvider } from "wagmi/providers/public"

export const WagmiProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { chains, provider, webSocketProvider } = configureChains(defaultChains, [publicProvider()])

  const client = createClient({
    connectors: [
      new InjectedConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
    ],
    autoConnect: true,
    provider,
    webSocketProvider,
  })
  return (
    // <WagmiConfig autoConnect w={provider} connectors={connectors}>
    <WagmiConfig client={client}>{children}</WagmiConfig>
  )
}
