import React, { PropsWithChildren, Fragment } from "react"
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi"
import config from "../../config"
import { shortenAddress } from "../../utils/format"
import { setUser } from "../../utils/sentry"
import WalletProviderModal from "../WalletProviderModal/WalletProviderModal"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

/**
 * Wallet connection component.
 *
 * It allows changing to the correct network and connecting
 * a wallet via WalletConnect or MetaMask.
 */
const ConnectButton: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const { switchNetwork } = useSwitchNetwork()
  const accountData = useAccount()
  const { chain } = useNetwork()

  /**
   * Set Sentry User
   */
  React.useEffect(() => {
    setUser(accountData.address ? { username: accountData.address } : null)
  }, [accountData.address])

  /**
   * Triggers a network switch to the correct network
   */
  const handleSwitchToCorrectNetwork = React.useCallback(() => {
    switchNetwork?.(config.chains[0].chainId)
  }, [switchNetwork])

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ")
  }

  /**
   * Toggles the connection modal visibility
   */
  const handleToggleConnectionModal = React.useCallback(() => {
    setIsModalVisible(!isModalVisible)
  }, [isModalVisible])

  // Check if correct network is selected
  const isCorrectNetwork = config.chains.some((element) => element.chainId === chain?.id)
  const selectedNetowrk = config.chains.filter((element) => element.chainId === chain?.id)

  return (
    <>
      <button
        className="bg-[#1976d2] hover:bg-[#1565c0] shadow-[rgb(0_0_0_/_20%)_0px_3px_1px_-2px,_rgb(0_0_0_/_14%)_0px_2px_2px_0px,_rgb(0_0_0_/_12%)_0px_1px_5px_0px] hover:shadow-[rgb(0_0_0_/_20%)_0px_2px_4px_-1px,_rgb(0_0_0_/_14%)_0px_4px_5px_0px,_rgb(0_0_0_/_12%)_0px_1px_10px_0px] text-white px-[10px] min-w-[120px] rounded-[4px] h-[40px] disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed transition-all font-semibold"
        onClick={
          accountData.isConnected
            ? isCorrectNetwork
              ? undefined
              : handleSwitchToCorrectNetwork
            : handleToggleConnectionModal
        }
      >
        {accountData.isConnected
          ? isCorrectNetwork
            ? shortenAddress(accountData.address)
            : "Switch network"
          : "Connect"}
        {!accountData.isConnected && isModalVisible && <WalletProviderModal onClose={handleToggleConnectionModal} />}
      </button>
      {isCorrectNetwork && (
        <Menu as="div" className="relative inline-block text-right ml-2">
          <div>
            <Menu.Button className="inline-flex w-full justify-center bg-[#1976d2] hover:bg-[#1565c0] shadow-[rgb(0_0_0_/_20%)_0px_3px_1px_-2px,_rgb(0_0_0_/_14%)_0px_2px_2px_0px,_rgb(0_0_0_/_12%)_0px_1px_5px_0px] hover:shadow-[rgb(0_0_0_/_20%)_0px_2px_4px_-1px,_rgb(0_0_0_/_14%)_0px_4px_5px_0px,_rgb(0_0_0_/_12%)_0px_1px_10px_0px] text-white px-[10px] min-w-[120px] rounded-[4px] h-[40px] disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed transition-all font-semibold items-center">
              {selectedNetowrk[0]?.name}
              <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-semibold">
              <div className="py-1">
                {config.chains.map((chain, index) => {
                  return (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm cursor-pointer text-blue-500"
                          )}
                          onClick={() => switchNetwork?.(chain.chainId)}
                        >
                          {chain.name}
                        </span>
                      )}
                    </Menu.Item>
                  )
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  )
}

export default ConnectButton
